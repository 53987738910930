import React from "react";
import CodeIcon from "../../images/code.svg";
import EngineeringTeam from "../../images/updated/undraw_engineering_team.svg";
// import AutomationIcon from '../../images/automation.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Specialty</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={EngineeringTeam} />
          <ServicesH2>A.I. Automation</ServicesH2>
          {/* <ServicesP>
            We specialize in React Native and Native Mobile Development
          </ServicesP> */}
        </ServicesCard>
        {/* <ServicesCard>
          <ServicesIcon src={AutomationIcon} />
          <ServicesH2>Automation</ServicesH2>
          <ServicesP>
            To scale software and improve results
          </ServicesP>
        </ServicesCard> */}
        {/* <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Premium Benefits</ServicesH2>
          <ServicesP>
            Unlock our special membership card that returns 5% cash back.
          </ServicesP>
        </ServicesCard> */}
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
