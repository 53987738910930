export const homeObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Focused and Skilled",
  headline: "100% Satisfaction Guaranteed or Your Money Back",
  // description: "Fixed-Rate/Hourly priced Software Engineering",
  description: "",
  buttonLabel: "View our Pricing",
  imgStart: false,
  img: require("../../images/updated/undraw_team_collaboration.svg").default,
  alt: "Team Collab",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Unlimited Access",
  headline: "Login to your account at any time",
  description:
    "We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.",
  buttonLabel: "Learn More",
  imgStart: true,
  img: require("../../images/svg-2.svg").default,
  alt: "Piggybank",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Contact Us",
  headline: "for your A.I. Automation needs...",
  description: "",
  buttonLabel: "Book an Appointment",
  imgStart: false,
  img: require("../../images/updated/undraw_contact_us.svg").default,
  alt: "Contact Us",
  dark: false,
  primary: false,
  darkText: true,
};
