import React, { useState } from "react";
import { ButtonR, Button } from "../ButtonElements";

import { animateScroll as scroll } from "react-scroll";

import Video from "../../videos/ocean.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";

import { useHistory } from "react-router-dom";

const HeroSection = () => {
  // const history = useHistory();
  // const handleCTAButtonClick = () => history.push('/signin');

  const [hover, setHover] = useState(false);
  const history = useHistory();

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        {/* <VideoBg playsInline autoPlay loop muted src={Video} type="video/mp4" /> */}
      </HeroBg>
      <HeroContent>
        <HeroH1>COMMAND LABS</HeroH1>
        <HeroP>A.I. Automation Agency</HeroP>
        <HeroBtnWrapper>
          <ButtonR
            primary="true"
            onClick={() => {
              window.location.href = process.env.REACT_APP_SKILLS_URL;
            }}
          >
            Skills
          </ButtonR>
          <ButtonR
            primary="true"
            onClick={() => {
              history.push("/pricing");
            }}
          >
            Pricing
          </ButtonR>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
